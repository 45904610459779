<script>
import leftPanelState from "../../../store/left-side-panel-state.js";
import ProjectSwitcher from "./ProjectSwitcher.vue";
import Expand from "../../../assets/vue-icons/tools/expand.vue";
import { computed, inject, nextTick, onBeforeUnmount, onMounted, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { BROADCAST_MESSAGE } from "../../../core/constants.js";
import { GET_CACHE, SET_CACHE, state as cacheState } from '../../../store/cacheLayer';
import ProjectService from "../../../service/projectService";
import { state as applicationState, getApplicationState, setApplicationState } from '../../../store/applicationState';

export default {
  name: "LeftSidePanel",
  components: { Expand, ProjectSwitcher },
  props: [],
  emits: [],

  setup(props) {
    const $fetch = inject("$fetch");
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      documents: [],
      sortedProjects: []
    });
    const leftSidePanelEL = ref(null);
    const showRecentMenu = ref(false);
    const hoveringOverFavDocStar = ref(null);
    const currentProject = ref(null);
    const isLoading = ref(false);
    const currentProjectId = computed(() => {
      return route.params.projectId;
    });
    const currentDocumentId = computed(() => {
      return route.params.documentId;
    });
    const favorite_documents = computed(() => {
      return state.documents
        .filter(doc => doc.isFavorite)
        .sort(sortShownDocuments);
    });
    const not_favorite_documents = computed(() => {
      return state.documents
        .filter(doc => !doc.isFavorite)
        .sort(sortShownDocuments);
    });
    const projectService = new ProjectService();
    onMounted(() => {
      getAllProjects();
      // getDocuments();      
      // $fetch.on(BROADCAST_MESSAGE.PROJECT_UPDATED, getDocuments);
      // $fetch.on(BROADCAST_MESSAGE.DOCUMENT_UPDATED, getDocument);
      // nextTick().then(() => {
      //   leftSidePanelEL.value.addEventListener("scroll", onScrollHandler);
      // });
      // document.addEventListener("click", handleClickOutside);
    });

    onBeforeUnmount(() => {
      // leftSidePanelEL.value.removeEventListener("scroll", onScrollHandler);
      // $fetch.off(BROADCAST_MESSAGE.PROJECT_UPDATED, getDocuments);
      // $fetch.off(BROADCAST_MESSAGE.DOCUMENT_UPDATED, getDocument);
    });

    watch(() => route.params.projectId, (projectId) => {
      if (projectId) {
        getDocuments(projectId);
        currentProject.value = state.sortedProjects.find(p => p.id === projectId);
      }


    });
    watch(() => cacheState.isDocumentsLoading, (d) => {
      // console.log("refresh doc list", d);
      // if (d) {
      //   getDocuments();
      // }
    })

    watch(() => applicationState.favoriteDocumentChange, () => {
      const { docId = null, isFavorite } = getApplicationState.setFavoriteDocumentChange();
      // console.log({docId,isFavorite});
      if (docId !== null) {
        const documents = state.documents;
        const document = documents.find(doc => doc.id === docId);
        if (!document) return;
        document.isFavorite = isFavorite;
        state.documents.sort((a, b) => {
          if (a.isFavorite === b.isFavorite) {
            return new Date(b.updatedAt) - new Date(a.updatedAt);
          }
          return a.isFavorite ? -1 : 1;
        });
      }
    })
    watch(() => applicationState.reloadDocuments, async () => {
      if (applicationState.reloadDocuments) {
        await getDocuments();
        setApplicationState.setDocumentReloadState(false)
      }

    })

    let endOfScrollTimer = null;
    function onScrollHandler(e) {
      leftSidePanelEL.value.style.setProperty("--thumb-color", "silver");
      clearTimeout(endOfScrollTimer);
      endOfScrollTimer = setTimeout(() => {
        leftSidePanelEL.value.style.setProperty("--thumb-color", "transparent");
      }, 250);
    }

    function sortShownDocuments(a, b) {
      const aUpdatedTime = new Date(a.updatedAt).getTime();
      const bUpdatedTime = new Date(b.updatedAt).getTime();
      return bUpdatedTime - aUpdatedTime;
    }

    async function getDocuments(projectId) {
      // currentProjectId.value = projectId ?? route.params.projectId
      // if (!currentProjectId.value) {
      //   state.documents = [];
      //   return ;
      // }
      state.documents = [];
      const pId = currentProjectId.value??route.params.projectId;
      // Get docs from the cache
      let allDocs = await GET_CACHE.getCachedDocuments(pId, true);
      if (allDocs.length === 0) {
        state.documents = [];
        return
      }
      const projectDocuments = allDocs.filter(d => d.projectId.toString() === pId?.toString());
      const sortedDocs = projectDocuments.sort((a, b) => {
        if (a.isFavorite === b.isFavorite) {
          return a.name.localeCompare(b.name);
        }
        return a.isFavorite ? -1 : 1;
      });
      state.documents = sortedDocs;
      // return $fetch.getDocuments(currentProjectId.value).then(documents => {
      //   state.documents = documents;
      // }).catch(console.error);
    }
    async function getAllProjects() {
      const cachedProjects = await GET_CACHE.getCachedProjects();
      // find the current project here and set it to currentProject
      const currentProjectId = route.params.projectId;
      currentProject.value = cachedProjects.find(p => p.id === currentProjectId);
      state.sortedProjects = cachedProjects.sort((a, b) => {
        const aTime = a.lastAccessedAt ? new Date(a.lastAccessedAt).getTime() : 0;
        const bTime = b.lastAccessedAt ? new Date(b.lastAccessedAt).getTime() : 0;
        return bTime - aTime;
      });    
      getDocuments(currentProjectId);
    }
    function getDocument(documentId) {
      return $fetch.getDocument(documentId).then(doc => {
        const documentIndex = state.documents.findIndex(d => d.id === doc.id);
        if (documentIndex === -1) return;
        state.documents[documentIndex] = doc;
      }).catch(console.error);
    }

    function switch2document(documentId) {
      const projectId = route.params.projectId || leftPanelState.fromProject;
      router.push(`/p/${projectId}/d/${documentId}`);
    }
    async function switch2Project(projectId) {
      await SET_CACHE.updateProjectLastAccessedAt(projectId);
      router.push(`/p/${projectId}`);
    }
    function handleClickOutside() {
      if(showRecentMenu.value){
        showRecentMenu.value = false;
      }
    }
    async function changeFavoriteStatus(documentId, isFavorite) {
      const email = localStorage.getItem("email");
      const tenantId = localStorage.getItem("tenantId");
      const document = state.documents.find(doc => doc.id === documentId);

      if (!document) return;
      document.isFavorite = isFavorite;
      // update cached doc
      SET_CACHE.updateDoc({ documentId, data: document })
      await projectService.MarkDocumentFavorite({ documentId, isFavorite, tenantId, email });
      getDocuments();
    }
    async function processRecentProjects() {
      console.log({ showRecentMenu: showRecentMenu.value });
      await getAllProjects();
      showRecentMenu.value = !showRecentMenu.value;
      // console.log({ showRecentMenu: showRecentMenu.value,show });      
    }
    function toggleDocumentsListView() {
      leftPanelState.documentsCollapsed
        ? leftPanelState.expandDocumentsList()
        : leftPanelState.collapseDocumentsList();
    }

    function toggleFavoriteDocumentsListView() {
      leftPanelState.favoriteDocumentsCollapsed
        ? leftPanelState.expandFavoriteDocumentsList()
        : leftPanelState.collapseFavoriteDocumentsList();
    }


    return {
      leftSidePanelEL,
      leftPanelState, state, currentProjectId, currentDocumentId, not_favorite_documents, favorite_documents,
      switch2document, changeFavoriteStatus, toggleDocumentsListView, toggleFavoriteDocumentsListView,
      showRecentMenu, hoveringOverFavDocStar, switch2Project, processRecentProjects, currentProject, isLoading,
      handleClickOutside
    }
  }
}
</script>

<template>
  <div class="ml-4">
    <div class="flex items-center mt-4 cursor-pointer mr-2" @click="$router.push({ name: 'home' })">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.75 20.25V15H14.25V20.25H20.25V11.25C20.2501 11.1515 20.2307 11.0539 20.1931 10.9628C20.1555 10.8718 20.1003 10.7891 20.0306 10.7194L12.5306 3.21935C12.461 3.14962 12.3783 3.0943 12.2872 3.05656C12.1962 3.01882 12.0986 2.99939 12 2.99939C11.9014 2.99939 11.8038 3.01882 11.7128 3.05656C11.6217 3.0943 11.539 3.14962 11.4694 3.21935L3.96938 10.7194C3.89975 10.7891 3.84454 10.8718 3.8069 10.9628C3.76926 11.0539 3.74992 11.1515 3.75 11.25V20.25H9.75Z"
          stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <span class="ml-1 text-gray-700 text-sm font-semibold">Home</span>
    </div>
    <div class="flex items-center mt-4 cursor-pointer mr-2 relative border-b border-gray-200 pb-4">
      <div @click="switch2Project(currentProjectId)" class="flex items-center">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 6.18994H7" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M4 10.0601H7" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M4 17.8101H7" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M4 13.9399H7" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M15.8799 12H19.9999" stroke="#334155" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M15.8799 8.12012H19.9999" stroke="#334155" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M15.88 3.25V14.04C15.88 14.42 16.1 14.77 16.44 14.94L18.87 16.12C19.56 16.45 20 17.15 20 17.92M15.88 3.25H6.5C6.43 3.25 6.37 3.25 6.3 3.27C6.23 3.29 6.17 3.31 6.11 3.33C6.05 3.35 6 3.39 5.94 3.42C5.88 3.45 5.83 3.49 5.79 3.54C5.75 3.59 5.7 3.64 5.67 3.69C5.64 3.74 5.61 3.8 5.58 3.86C5.55 3.92 5.54 3.99 5.52 4.05C5.5 4.11 5.5 4.18 5.5 4.25V19.75C5.5 19.82 5.5 19.88 5.52 19.95C5.54 20.02 5.56 20.08 5.58 20.14C5.6 20.2 5.64 20.25 5.67 20.31C5.7 20.37 5.74 20.42 5.79 20.46C5.84 20.5 5.89 20.55 5.94 20.58C5.99 20.61 6.05 20.64 6.11 20.67C6.17 20.7 6.24 20.71 6.3 20.73C6.36 20.75 6.43 20.75 6.5 20.75H19C19.07 20.75 19.13 20.75 19.2 20.73C19.27 20.71 19.33 20.69 19.39 20.67C19.45 20.65 19.5 20.61 19.56 20.58C19.62 20.55 19.67 20.51 19.71 20.46C19.75 20.41 19.8 20.36 19.83 20.31C19.86 20.26 19.89 20.2 19.92 20.14C19.95 20.08 19.96 20.01 19.98 19.95C20 19.89 20 19.82 20 19.75V17.92M15.88 3.25V4.25H19C19.07 4.25 19.14 4.25 19.2 4.27C19.26 4.29 19.33 4.3 19.39 4.33C19.45 4.36 19.51 4.39 19.56 4.42C19.61 4.45 19.66 4.5 19.71 4.54C19.76 4.58 19.8 4.63 19.83 4.69C19.86 4.75 19.9 4.8 19.92 4.86C19.94 4.92 19.96 4.98 19.98 5.05C20 5.12 20 5.18 20 5.25V17.92"
            stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span class="ml-1 text-gray-700 text-sm font-semibold max-w-44 truncate" :title="currentProject?.name ?? 'Current project'">
          {{ currentProject?.name ?? 'Current project' }}
        </span>

      </div>


      <div @click="processRecentProjects();" class="absolute right-0" v-click-outside="handleClickOutside">
        <svg v-if="!showRecentMenu" width="20" height="20" viewBox="0 0 20 20" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M6 12L10 16L14 12" stroke="#334155" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M6 8L10 4L14 8" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" rx="12" fill="white" />
          <rect width="24" height="24" rx="12" fill="#EAF4FF" />
          <path d="M8 14L12 18L16 14" stroke="#2990FF" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M8 10L12 6L16 10" stroke="#2990FF" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </div>
      <div v-if="showRecentMenu"
        class="absolute z-10 min-h-[288px] w-full top-8 border shadow-md border-gray-200 flex flex-col bg-white">
        <div class="flex flex-col m-2">
          <div class="flex flex-col m-2">
            <span class="text-sm text-gray-700 font-semibold">Recent projects</span>
            <span class="text-xs text-gray-500">Sorted by last opened</span>
          </div>
          <div v-for="project in state.sortedProjects.slice(0,4)" :key="project.id"
            class="flex mt-2 hover:bg-blue-50  p-2 rounded-sm w-full"
            :class="project.id === currentProjectId ? 'bg-blue-50' : ''"
            @click="switch2Project(project.id); showRecentMenu = false">
            <svg v-if="project.id === currentProjectId" width="24" height="24" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M3.99991 6.19019H6.99991" stroke="#2990FF" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M3.99991 10.0601H6.99991" stroke="#2990FF" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M3.99991 17.8101H6.99991" stroke="#2990FF" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M3.99991 13.9402H6.99991" stroke="#2990FF" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M15.8801 12H20.0001" stroke="#2990FF" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M15.8801 8.12012H20.0001" stroke="#2990FF" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path
                d="M15.8799 3.25V14.04C15.8799 14.42 16.0999 14.77 16.4399 14.94L18.8699 16.12C19.5599 16.45 19.9999 17.15 19.9999 17.92M15.8799 3.25H6.49991C6.42991 3.25 6.36991 3.25 6.29991 3.27C6.22991 3.29 6.16991 3.31 6.10991 3.33C6.04991 3.35 5.99991 3.39 5.93991 3.42C5.87991 3.45 5.82991 3.49 5.78991 3.54C5.74991 3.59 5.69991 3.64 5.66991 3.69C5.63991 3.74 5.60991 3.8 5.57991 3.86C5.54991 3.92 5.53991 3.99 5.51991 4.05C5.49991 4.11 5.49991 4.18 5.49991 4.25V19.75C5.49991 19.82 5.49991 19.88 5.51991 19.95C5.53991 20.02 5.55991 20.08 5.57991 20.14C5.59991 20.2 5.63991 20.25 5.66991 20.31C5.69991 20.37 5.73991 20.42 5.78991 20.46C5.83991 20.5 5.88991 20.55 5.93991 20.58C5.98991 20.61 6.04991 20.64 6.10991 20.67C6.16991 20.7 6.23991 20.71 6.29991 20.73C6.35991 20.75 6.42991 20.75 6.49991 20.75H18.9999C19.0699 20.75 19.1299 20.75 19.1999 20.73C19.2699 20.71 19.3299 20.69 19.3899 20.67C19.4499 20.65 19.4999 20.61 19.5599 20.58C19.6199 20.55 19.6699 20.51 19.7099 20.46C19.7499 20.41 19.7999 20.36 19.8299 20.31C19.8599 20.26 19.8899 20.2 19.9199 20.14C19.9499 20.08 19.9599 20.01 19.9799 19.95C19.9999 19.89 19.9999 19.82 19.9999 19.75V17.92M15.8799 3.25V4.25H18.9999C19.0699 4.25 19.1399 4.25 19.1999 4.27C19.2599 4.29 19.3299 4.3 19.3899 4.33C19.4499 4.36 19.5099 4.39 19.5599 4.42C19.6099 4.45 19.6599 4.5 19.7099 4.54C19.7599 4.58 19.7999 4.63 19.8299 4.69C19.8599 4.75 19.8999 4.8 19.9199 4.86C19.9399 4.92 19.9599 4.98 19.9799 5.05C19.9999 5.12 19.9999 5.18 19.9999 5.25V17.92"
                stroke="#2990FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.99991 6.19019H6.99991" stroke="#334155" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M3.99991 10.0601H6.99991" stroke="#334155" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M3.99991 17.8101H6.99991" stroke="#334155" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M3.99991 13.9402H6.99991" stroke="#334155" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M15.8801 12H20.0001" stroke="#334155" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M15.8801 8.12012H20.0001" stroke="#334155" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path
                d="M15.8799 3.25V14.04C15.8799 14.42 16.0999 14.77 16.4399 14.94L18.8699 16.12C19.5599 16.45 19.9999 17.15 19.9999 17.92M15.8799 3.25H6.49991C6.42991 3.25 6.36991 3.25 6.29991 3.27C6.22991 3.29 6.16991 3.31 6.10991 3.33C6.04991 3.35 5.99991 3.39 5.93991 3.42C5.87991 3.45 5.82991 3.49 5.78991 3.54C5.74991 3.59 5.69991 3.64 5.66991 3.69C5.63991 3.74 5.60991 3.8 5.57991 3.86C5.54991 3.92 5.53991 3.99 5.51991 4.05C5.49991 4.11 5.49991 4.18 5.49991 4.25V19.75C5.49991 19.82 5.49991 19.88 5.51991 19.95C5.53991 20.02 5.55991 20.08 5.57991 20.14C5.59991 20.2 5.63991 20.25 5.66991 20.31C5.69991 20.37 5.73991 20.42 5.78991 20.46C5.83991 20.5 5.88991 20.55 5.93991 20.58C5.98991 20.61 6.04991 20.64 6.10991 20.67C6.16991 20.7 6.23991 20.71 6.29991 20.73C6.35991 20.75 6.42991 20.75 6.49991 20.75H18.9999C19.0699 20.75 19.1299 20.75 19.1999 20.73C19.2699 20.71 19.3299 20.69 19.3899 20.67C19.4499 20.65 19.4999 20.61 19.5599 20.58C19.6199 20.55 19.6699 20.51 19.7099 20.46C19.7499 20.41 19.7999 20.36 19.8299 20.31C19.8599 20.26 19.8899 20.2 19.9199 20.14C19.9499 20.08 19.9599 20.01 19.9799 19.95C19.9999 19.89 19.9999 19.82 19.9999 19.75V17.92M15.8799 3.25V4.25H18.9999C19.0699 4.25 19.1399 4.25 19.1999 4.27C19.2599 4.29 19.3299 4.3 19.3899 4.33C19.4499 4.36 19.5099 4.39 19.5599 4.42C19.6099 4.45 19.6599 4.5 19.7099 4.54C19.7599 4.58 19.7999 4.63 19.8299 4.69C19.8599 4.75 19.8999 4.8 19.9199 4.86C19.9399 4.92 19.9599 4.98 19.9799 5.05C19.9999 5.12 19.9999 5.18 19.9999 5.25V17.92"
                stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <span
              :class="project.id === currentProjectId ? 'ml-1 text-sm text-blue-500' : 'ml-1 text-sm text-gray-700'" class="truncate max-w-36">{{
                project.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col mt-4 cursor-pointer mr-2">
      <div class="text-sm text-gray-700 font-semibold">
        All documents
      </div>
      <div class="max-h-[80svh] overflow-y-scroll overflow-x-clip">
        <div v-if="!isLoading" v-for="doc in state.documents" :key="doc.id"
          class="flex mt-2 p-1 items-center relative hover:bg-blue-50">
          <div @click="switch2document(doc.id)" class="flex items-center w-full">
            <svg class="mr-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19.25 8.69V18.75C19.25 19.85 18.35 20.75 17.25 20.75H6.75C5.65 20.75 4.75 19.85 4.75 18.75V5.25C4.75 4.15 5.65 3.25 6.75 3.25H13.81L19.25 8.69Z"
                stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M19.2501 8.69H13.8101V3.25" stroke="#334155" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M8.75 13H15.25" stroke="#334155" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M8.75 16.25H15.25" stroke="#334155" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            <div class="flex items-center max-w-[145px] lg:max-w-[155px] xl:max-w-[155px] overflow-hidden text-sm whitespace-nowrap" :title="doc.name">
              {{ doc.name }}
            </div>

          </div>

          <div class="absolute right-0" @click="changeFavoriteStatus(doc.id, !doc.isFavorite)">
            <svg
                :class="[
                doc.isFavorite ? 'fill-yellow-400 stroke-yellow-400 hover:fill-yellow-400 hover:stroke-yellow-400' : 'fill-white  md:stroke-gray-200 xl:stroke-white hover:fill-white hover:stroke-gray-400 md:hover:stroke-gray-400',
                ''
                ]"
              width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.5234 3.69741C11.6712 3.23288 12.3286 3.23288 12.4764 3.69741L14.2063 9.13431C14.2723 9.3418 14.465 9.48271 14.6828 9.48271H20.1426C20.6187 9.48271 20.8254 10.0851 20.4496 10.3774L15.9044 13.9125C15.7451 14.0365 15.6759 14.244 15.729 14.4388L17.2137 19.8826C17.342 20.353 16.7898 20.7101 16.4134 20.4001L12.3178 17.0272C12.1331 16.8752 11.8667 16.8752 11.682 17.0272L7.58637 20.4001C7.20997 20.7101 6.65784 20.353 6.78613 19.8826L8.27082 14.4388C8.32393 14.244 8.25474 14.0365 8.09541 13.9125L3.5502 10.3774C3.17441 10.0851 3.38109 9.48271 3.85717 9.48271H9.31705C9.53479 9.48271 9.7275 9.3418 9.79351 9.13431L11.5234 3.69741Z"
                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>
        <div v-else>
          loading
        </div>
      </div>

    </div>
  </div>
  <!-- <div class="left-side-panel" ref="leftSidePanelEL">
    <ProjectSwitcher @hide-panel="" />
    <div class="documents-list">

      <div class="documents-block" v-if="favorite_documents.length">
        <div class="block-header">
          <span>FAVORITE DOCUMENTS</span>
          <button class="expand-button" @click="toggleFavoriteDocumentsListView"
            :class="{ 'collapse': !leftPanelState.favoriteDocumentsCollapsed }"></button>
        </div>
        <transition name="expand">
          <div class="block-items" v-show="!leftPanelState.favoriteDocumentsCollapsed">
            <div class="block-item" v-for="document of favorite_documents" :key="document.id"
              :class="{ 'active': document.id === currentDocumentId }" @click="switch2document(document.id)">
              <div class="item-title">
                <div class="title-icon"></div>
                <span class="title-text">{{ document.name }}</span>
              </div>
              <button class="item-action favorite" @click.stop="changeFavoriteStatus(document.id, false)"></button>
            </div>
          </div>
        </transition>
      </div>

      <div class="documents-block" v-if="not_favorite_documents.length">
        <div class="block-header">
          <span>ALL DOCUMENTS</span>
          <button class="expand-button" @click="toggleDocumentsListView"
            :class="{ 'collapse': !leftPanelState.documentsCollapsed }"></button>
        </div>
        <transition name="expand">
          <div class="block-items" v-show="!leftPanelState.documentsCollapsed">
            <div class="block-item" v-for="document of not_favorite_documents" :key="document.id"
              :class="{ 'active': document.id === currentDocumentId }" @click="switch2document(document.id)">
              <div class="item-title">
                <div class="title-icon"></div>
                <span class="title-text">{{ document.name }}</span>
              </div>
              <button class="item-action not-favorite" @click.stop="changeFavoriteStatus(document.id, true)"></button>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div> -->
</template>

<style scoped lang="scss">
@keyframes collapseAnimation {
  0% {
    max-height: 100vh;
  }

  100% {
    max-height: 0;
  }
}

@keyframes expandAnimation {
  0% {
    max-height: 0;
  }

  100% {
    max-height: 100vh;
  }
}

::-webkit-scrollbar {
  appearance: none;
  width: 5px;
}

::-webkit-scrollbar-track {}

::-webkit-scrollbar-thumb {
  appearance: none;
  display: block;
  width: 5px;
  border-radius: 5px;
  background-color: var(--thumb-color);
}

//::-webkit-scrollbar-thumb:hover {
//  background-color: silver;
//}

.left-side-panel {
  --thumb-color: transparent;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  user-select: none;
  overflow-y: auto;
  background-color: #ffffff;

  .documents-list {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 12px;
    position: absolute;
    top: 90px;
    //max-height: 300px;
    //overflow-y: scroll;

    .documents-block {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .block-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px;

        span {
          color: #1E293B;
          font-family: Inter, sans-serif;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: 12px;
          letter-spacing: 0.6px;
        }

        .expand-button {
          border: none;
          width: 16px;
          height: 16px;
          background-color: transparent;
          background-image: url("data:image/svg+xml, %3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.6663 10.6667L7.99967 5.33333L3.33301 10.6667' stroke='%231E293B' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          cursor: pointer;

          &:active {
            transform: translate(1px, 1px);
          }

          &.collapse {
            transform: rotate(180deg);
          }
        }
      }

      .block-items {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        gap: 4px;

        &.expanded {
          max-height: 100vh;
          animation: expandAnimation 0.25s;
        }

        &.collapsed {
          max-height: 0;
          animation: collapseAnimation 0.25s;
        }

        .block-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 4px 8px 4px 8px;
          border-radius: 8px;
          color: #334155;

          &.active {
            background-color: #EAF4FF;

            .item-title {
              .title-text {
                color: #2990FF;
              }

              .title-icon {
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 9.25H13.75V5M9.75 15.25H14.25M9.75 12.25H14.25M16.25 19.25H7.75C6.64543 19.25 5.75 18.3546 5.75 17.25V6.75C5.75 5.64543 6.64543 4.75 7.75 4.75H14L18.25 9V17.25C18.25 18.3546 17.3546 19.25 16.25 19.25Z' stroke='%232990FF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
              }
            }
          }

          &:hover {
            background-color: #F1F5F9;
            cursor: pointer;
          }

          .item-title {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .title-icon {
              width: 24px;
              height: 24px;
              background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 9.25H13.75V5M9.75 15.25H14.25M9.75 12.25H14.25M16.25 19.25H7.75C6.64543 19.25 5.75 18.3546 5.75 17.25V6.75C5.75 5.64543 6.64543 4.75 7.75 4.75H14L18.25 9V17.25C18.25 18.3546 17.3546 19.25 16.25 19.25Z' stroke='%23475569' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
              background-size: 24px;
            }

            .title-text {
              font-family: Inter, sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;

              min-width: 150px;
              max-width: 150px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .item-action {
            border: none;
            background-color: transparent;
            width: 24px;
            height: 24px;
            border-radius: 5px;

            &:hover {
              background-color: #d8dce0;
              cursor: pointer;
            }

            &:active {
              transform: translate(1px, 1px);
            }

            &.favorite {
              background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 5L13.6897 10.3103H19L14.6552 13.6897L16.1034 19L12 15.6207L7.89655 19L9.34483 13.6897L5 10.3103H10.3103L12 5Z' fill='%23F59E0B' stroke='%23F59E0B' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            }

            &.not-favorite {
              background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 5L13.6897 10.3103H19L14.6552 13.6897L16.1034 19L12 15.6207L7.89655 19L9.34483 13.6897L5 10.3103H10.3103L12 5Z' stroke='%2394A3B8' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            }
          }
        }
      }
    }
  }
}
</style>